/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/** *************************************************************************************************
 * BROWSER POLYFILLS
 */

/** *************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
/* (window as any).__Zone_disable_PromiseRejectionEvent = true;
   (window as any).__Zone_disable_requestAnimationFrame = true;
   (window as any).__Zone_disable_bluebird = true; */
(window as any).__Zone_disable_IE_check = true
/* (window as any).__Zone_disable_XHR = true;
   (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
   (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames */

// Included with Angular CLI.
import "zone.js"
/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// Run `npm install --save intl`.
import "intl"
import "intl/locale-data/jsonp/en"
import "intl/locale-data/jsonp/ru"
/** ****************************************************************
 * Load `$localize` - used if i18n tags appear in Angular templates.
 */
import "@angular/localize/init"

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

/* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
   with the following flag, it will bypass `zone.js` patch for IE/Edge */
// (window as any).__Zone_enable_cross_context_check = true;


/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Need to import at least one locale-data with intl.
 */
